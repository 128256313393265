import React, { useMemo } from 'react';

import { colors } from '../../styles/atoms/colors';

import CallToAction from '../../molecules/CallToAction/CallToAction';

import Section from '../../molecules/Section/Section';

import { Heading, HeadingWrapper, Wrapper } from './AwardStyles';

import Img from '../../utils/OptimizedImage';

const AwardComponent = ({
  component: { id, heading, featuredContent, theme, callToAction },
}) => {
  const styleHierarchy = {
    White: { bg: colors.base.white, button: 'primary' },
    Gray: { bg: colors.base.heroSurface, button: 'primary' },
    Charcoal: { bg: colors.gray[900], button: 'secondary' },
  };

  const content = useMemo(
    () =>
      featuredContent && (
        <div className="media">
          {featuredContent?.gatsbyImageData && (
            <Img
              image={featuredContent?.gatsbyImageData}
              src={featuredContent?.url}
              alt={featuredContent?.internalName}
              title={featuredContent?.title}
            />
          )}
        </div>
      ),
    [featuredContent]
  );

  return (
    <Section id={id} bgColor={styleHierarchy[theme]?.bg || colors.base.white}>
      <Wrapper>
        <HeadingWrapper>
          {heading && <Heading>{heading}</Heading>}
          {callToAction && (
            <CallToAction
              variant={styleHierarchy[theme]?.button || 'primary'}
              size={callToAction?.size}
              value={callToAction?.label}
              link={callToAction?.url}
              icon={callToAction?.icon}
            />
          )}
        </HeadingWrapper>
        {content}
      </Wrapper>
    </Section>
  );
};

export default AwardComponent;
